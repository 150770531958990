import { gql } from '@apollo/client';

export const CURATED_ARTIST_FRAGMENT = gql`
  fragment CuratedArtist on Studio {
    id
    name
    permalinkOrId
    bannerImage {
      ...BannerImage
    }
  }
`;
