import React from 'react';
import { CheckboxRadio } from './CheckboxRadio';

export interface RadioProps {
  children?: React.ReactNode;
  checked?: boolean;
  variant?: 'tag';
}

export const Radio = React.forwardRef<
  HTMLInputElement,
  React.InputHTMLAttributes<HTMLInputElement> & RadioProps
>(({ className, variant, children, ...props }, ref) => {
  return (
    <CheckboxRadio
      type="radio"
      variant={variant}
      className={className}
      input={
        <input
          ref={ref}
          type="radio"
          className="form-radio checked:border-gray-900 hover:checked:border-gray-900 focus:checked:border-gray-900"
          {...props}
        />
      }
    >
      {children}
    </CheckboxRadio>
  );
});
