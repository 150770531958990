import React from 'react';
import { Input } from '@components/ui/Input';
import { Search, X } from 'react-feather';
import { useForm } from 'react-hook-form';
import { useEffectSkipFirstRender } from '@lib/hooks/useEffectSkipFirstRender';
import { useEffect } from 'react';
import { useDebounce } from '@lib/hooks/useDebounce';

interface Values {
  query: string;
}

export function SearchArtworks({
  initialSearchQuery,
  debounceMs = 300,
  onQueryToTrackChanged,
  onSubmit,
}: {
  initialSearchQuery: string;
  debounceMs?: number;
  onQueryToTrackChanged: (query: string) => void;
  onSubmit: (query: string) => void;
}) {
  const { register, handleSubmit, watch, reset } = useForm<Values>({
    defaultValues: {
      query: initialSearchQuery,
    },
  });
  const query = watch('query');

  const debouncedQuery = useDebounce(query, debounceMs);
  useEffectSkipFirstRender(() => {
    onSubmit(debouncedQuery);
  }, [debouncedQuery]);

  useEffect(() => {
    if (initialSearchQuery === '') {
      reset({ query: initialSearchQuery });
    }
  }, [initialSearchQuery]);

  // slightly longer debounce here, to prevent tracking while typing
  const trackingQuery = useDebounce(query, 5000);
  useEffect(() => {
    if (trackingQuery) {
      onQueryToTrackChanged(trackingQuery);
    }
  }, [trackingQuery]);
  return (
    <form
      className="w-64"
      onSubmit={handleSubmit((data) => {
        onSubmit(data.query);
      })}
    >
      <Input
        id="search-query"
        aria-label="Search"
        placeholder="Artist or artwork"
        rounded="full"
        iconEnd={
          query ? (
            <button
              type="button"
              aria-label="Clear search"
              title="Clear search"
              onClick={() => {
                reset({ query: '' });
              }}
            >
              <X strokeWidth={1} />
            </button>
          ) : (
            <Search strokeWidth={1} />
          )
        }
        {...register('query')}
      />
    </form>
  );
}
