import { SanityStartPage } from '@lib/sanity.server';
import React from 'react';
import { gql } from '@apollo/client';
import {
  CURATED_ARTWORK_FRAGMENT,
  IMPRESSION_ARTWORK_ITEM_FRAGMENT,
} from '@lib/graphql/fragments/artwork';
import { CURATED_ARTIST_FRAGMENT } from '@lib/graphql/fragments/artist';
import { CurationSection } from '../common/Sliders/sliders';
import { useAuthUser } from '@lib/authentication';

gql`
  ${CURATED_ARTWORK_FRAGMENT}
  ${IMPRESSION_ARTWORK_ITEM_FRAGMENT}
  query curatedArtwork($artworkId: ID!) {
    artwork(id: $artworkId) {
      ...CuratedArtwork
      ...ImpressionArtworkItem
    }
  }
`;
gql`
  ${CURATED_ARTIST_FRAGMENT}
  query curatedArtist($artistId: ID!) {
    studio(id: $artistId) {
      ...CuratedArtist
    }
  }
`;

export function CurationsSection({
  curations,
}: {
  curations: NonNullable<SanityStartPage['curations']>;
}) {
  const userAuth = useAuthUser();

  return (
    <div className="space-y-8">
      {curations.map((curation) => {
        if (!curation.items || curation.items.length === 0) {
          return null;
        }

        return (
          <div key={curation._id}>
            <CurationSection
              title={curation.title}
              description={curation?.description}
              items={curation.items}
              userAuth={userAuth}
            />
          </div>
        );
      })}
    </div>
  );
}
