import React, { useCallback } from 'react';
import { LoadingSpinner } from '@components/ui/LoadingSpinner';
import { InlineError } from '@components/ui/InlineError';
import { ArtworksQuery } from '@generated/codegen';
import { ApolloError } from '@apollo/client';
import MasonryArtworkList from '@components/artworks/MasonryArtworkList';
import { AnimatePresence, motion, useReducedMotion } from 'framer-motion';
import classNames from 'classnames';
import { useArtworkImpressionTracker } from '@lib/analytics';
import { Button } from '@components/ui/Button';

// auto load 3 pages before asking the user to load more pages
const autoLoadPagesThreshold = 3;

export function InfiniteArtworkList({
  haveFilters,
  onLoadMore,
  loading,
  data,
  error,
  page,
  haveMorePages,
  itemListId,
  itemListName,
}: {
  loading: boolean;
  onLoadMore: () => void;
  data: ArtworksQuery | undefined;
  error: ApolloError | undefined;
  haveFilters: boolean;
  haveMorePages: boolean;
  itemListId: string;
  itemListName: string;
  page: number;
}) {
  const reducedMotion = useReducedMotion();
  const { onArtworkImpression, onArtworkSelect } = useArtworkImpressionTracker(
    itemListId,
    itemListName
  );

  const shouldAutoLoadNextPage =
    haveMorePages && (page + 1) % autoLoadPagesThreshold !== 0;
  const manuallyLoadMore = !loading && !shouldAutoLoadNextPage && haveMorePages;

  const onLastItemInView = useCallback(() => {
    if (shouldAutoLoadNextPage) {
      onLoadMore();
    }
  }, [onLoadMore, shouldAutoLoadNextPage]);
  const haveHits = Boolean(data && data.artworks.hits.length > 0);
  const reachedEnd = haveHits && !haveMorePages && !loading;

  return (
    <div
      className={classNames('relative overflow-hidden')}
      data-testid="ArtworkList"
    >
      {error && (
        <InlineError>Whoops, could not retrieve the artworks</InlineError>
      )}
      {data && (
        <>
          {data.artworks.hits.length === 0 && (
            <div className="mb-4 space-y-2 text-center">
              <p>Could not find any artworks.</p>
              {haveFilters && <p>Remove filters to see all artworks.</p>}
            </div>
          )}
          {haveHits && (
            <div className="min-h-screen">
              <MasonryArtworkList
                items={data.artworks.hits}
                haveMorePages={haveMorePages}
                onLastItemInView={onLastItemInView}
                onArtworkImpression={onArtworkImpression}
                onArtworkSelect={onArtworkSelect}
                loading={loading}
              />
            </div>
          )}
        </>
      )}
      <motion.div
        className="relative z-30 flex items-center justify-center h-8 mb-4"
        animate={loading || reachedEnd ? { height: 'auto' } : undefined}
        initial={{ height: 0 }}
      >
        <AnimatePresence initial={false} exitBeforeEnter>
          {manuallyLoadMore && (
            <motion.div
              key="manuallyLoadMore"
              initial={{ y: !reducedMotion ? 200 : undefined, opacity: 0 }}
              exit={{
                y: !reducedMotion ? 200 : undefined,
                opacity: 0,
                transition: { duration: 0.2 },
              }}
              animate={{
                y: -50,
                opacity: 1,
              }}
            >
              <Button
                type="button"
                variant="primaryYellow"
                onClick={() => {
                  onLoadMore();
                }}
                className="shadow-md"
              >
                See more artworks
              </Button>
            </motion.div>
          )}
          {loading && (
            <motion.div
              key="loading"
              initial={{ opacity: 0 }}
              transition={{
                duration: 0.3,
              }}
              exit={{
                opacity: 0,
              }}
              animate={{
                opacity: 1,
              }}
            >
              <LoadingSpinner />
            </motion.div>
          )}
        </AnimatePresence>
      </motion.div>
    </div>
  );
}
