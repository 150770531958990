import React from 'react';
import { AtelierLink } from '../common/routing';
import { EditionsSection } from '../common/Sliders/sliders';
import { useAuthUser } from '@lib/authentication';

export function HomepageEditionsSection({ data }: { data: any | [] }) {
  const userAuth = useAuthUser();

  return (
    <div className="space-y-8">
      <EditionsSection
        title="Atelie editions"
        description={
          <>
            Limited edition silk screen prints crafted by handpicked artists.
            Read more{` `}
            <AtelierLink href={'/editions'} prefetch={false}>
              <a className="underline">here</a>
            </AtelierLink>
            .
          </>
        }
        items={data.editions}
        userAuth={userAuth}
      />
    </div>
  );
}
