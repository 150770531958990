import { gql } from '@apollo/client';

export const ARTWORK_FORMATTED_PRICE_FRAGMENT = gql`
  fragment ArtworkFormattedPrice on FormattedCurrency {
    formatted
    value
  }
`;

export const ARTWORK_LIST_ITEM = gql`
  ${ARTWORK_FORMATTED_PRICE_FRAGMENT}
  fragment ArtworkListItem on Artwork {
    id
    title
    formattedPrice {
      ...ArtworkFormattedPrice
    }
    status
    currency
    bkhPrice
    basePrice
    images {
      id
      url
      servingUrl
      width
      height
    }
    studio {
      id
      name
      permalinkOrId
    }

    infoText
    width
    height
    depth
    year
    techniques
  }
`;

export const PAGINATED_ARTWORK_FRAGMENT = gql`
  ${ARTWORK_LIST_ITEM}
  fragment PaginatedArtwork on Artwork {
    ...ArtworkListItem
    studio {
      id
      name
      permalinkOrId
    }
  }
`;

export const IMPRESSION_ARTWORK_ITEM_FRAGMENT = gql`
  fragment ImpressionArtworkItem on Artwork {
    id
    title
    formattedPrice {
      value
    }
    studio {
      id
      name
    }
  }
`;

export const CURATED_ARTWORK_FRAGMENT = gql`
  fragment CuratedArtwork on Artwork {
    id
    title
    currency
    bkhPrice
    basePrice
    images {
      id
      url
      width
      height
    }
    formattedPrice {
      formatted
      value
    }
    studio {
      id
      name
      permalinkOrId
    }
  }
`;
