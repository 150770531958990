import React, { useCallback, useEffect, useReducer } from 'react';
import { InfiniteArtworkList } from './InfiniteArtworkList';
import { Filters, FilterValues } from './Filters';
import { SearchArtworks } from './SearchArtworks';
import { Button } from '@components/ui/Button';
import { gql } from '@apollo/client';
import {
  AlgoliaArtworkIndex,
  ArtworksQueryVariables,
  useArtworksQuery,
} from '@generated/codegen';
import { LoadingSpinner } from '@components/ui/LoadingSpinner';
import { Heading } from '@components/ui/Heading';
import { numberFormat } from '@lib/number-format';
import {
  IMPRESSION_ARTWORK_ITEM_FRAGMENT,
  PAGINATED_ARTWORK_FRAGMENT,
} from '@lib/graphql/fragments/artwork';
import { trackSearch } from '@lib/analytics';
import { motion, AnimatePresence } from 'framer-motion';

const initialPerPage = process.env.NEXT_PUBLIC_ARTWORKS_PER_PAGE
  ? parseInt(process.env.NEXT_PUBLIC_ARTWORKS_PER_PAGE)
  : 30;
const initialState = {
  page: 0,
  showFilters: false,
  filterValues: {
    index: AlgoliaArtworkIndex.Artworks,
    techniques: [],
    minPrice: '',
    maxPrice: '',
  },
  searchQuery: '',
};
export const initialVariables: ArtworksQueryVariables = {
  perPage: initialPerPage,
  page: initialState.page,
  index: initialState.filterValues.index,
  query: initialState.searchQuery,
  rental: false,
  maxPrice: undefined,
  minPrice: undefined,
  techniques: initialState.filterValues.techniques,
};
const MIN_PRICE = 0;
const MAX_PRICE = 75000;

export const ARTWORKS_QUERY = gql`
  ${PAGINATED_ARTWORK_FRAGMENT}
  ${IMPRESSION_ARTWORK_ITEM_FRAGMENT}
  query Artworks(
    $page: NonNegativeInt!
    $perPage: PositiveInt!
    $index: AlgoliaArtworkIndex!
    $query: String!
    $minPrice: Int
    $maxPrice: Int
    $techniques: [ID!]!
    $rental: Boolean!
  ) {
    artworks(
      page: $page
      perPage: $perPage
      index: $index
      query: $query
      minPrice: $minPrice
      maxPrice: $maxPrice
      techniques: $techniques
      rental: $rental
    ) {
      nbHits
      haveMorePages
      hits {
        ...PaginatedArtwork
        ...ImpressionArtworkItem
      }
    }
  }
`;

type State = {
  page: number;
  searchQuery: string;
  showFilters: boolean;
  filterValues: FilterValues;
};

function reducer(
  state: State,
  action:
    | { type: 'NEXT_PAGE' }
    | { type: 'TOGGLE_FILTERS' }
    | { type: 'SEARCH_QUERY'; payload: string }
    | { type: 'UPDATE_FILTER_VALUES'; payload: FilterValues }
    | { type: 'RESET_FILTERS' }
) {
  switch (action.type) {
    case 'NEXT_PAGE':
      return { ...state, page: state.page + 1 };
    case 'UPDATE_FILTER_VALUES':
      return { ...state, filterValues: action.payload };
    case 'TOGGLE_FILTERS':
      return { ...state, showFilters: !state.showFilters };
    case 'SEARCH_QUERY':
      return { ...state, searchQuery: action.payload, page: initialState.page };
    case 'RESET_FILTERS':
      return { ...initialState, showFilters: state.showFilters };
    default:
      throw new Error();
  }
}

export function Discover({ studioCount }: { studioCount: number }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { showFilters, searchQuery, filterValues, page } = state;
  const onReset = useCallback(() => {
    dispatch({
      type: 'RESET_FILTERS',
    });
  }, []);

  const { data, error, loading, fetchMore } = useArtworksQuery({
    notifyOnNetworkStatusChange: true,
    variables: {
      page: initialVariables.page,
      perPage: initialVariables.perPage,
      index: filterValues.index,
      query: searchQuery,
      minPrice: filterValues.minPrice
        ? parseInt(filterValues.minPrice)
        : undefined,
      maxPrice: filterValues.maxPrice
        ? parseInt(filterValues.maxPrice)
        : undefined,
      techniques: filterValues.techniques,
      rental: initialVariables.rental,
    },
  });

  let filtersCount = 0;

  if (filterValues.index !== initialState.filterValues.index) {
    filtersCount++;
  }
  if (filterValues.maxPrice !== initialState.filterValues.maxPrice) {
    filtersCount++;
  }
  if (filterValues.minPrice !== initialState.filterValues.minPrice) {
    filtersCount++;
  }
  if (
    filterValues.techniques.length !==
    initialState.filterValues.techniques.length
  ) {
    filtersCount += filterValues.techniques.length;
  }
  const haveFilters = filtersCount > 0;
  const haveMorePages = Boolean(data && data.artworks.haveMorePages);

  useEffect(() => {
    if (haveMorePages && page !== initialState.page) {
      fetchMore({ variables: { page } });
    }
  }, [page, haveMorePages]);

  const onLoadMore = useCallback(() => {
    if (loading || !haveMorePages) {
      return;
    }
    dispatch({ type: 'NEXT_PAGE' });
  }, [haveMorePages, loading]);

  return (
    <div data-testid="Discover">
      <div className="items-center mb-4 space-y-4 sm:flex sm:space-x-4 sm:space-y-0">
        <Heading
          level="h2"
          size="sm"
          className="flex justify-center flex-1 sm:justify-start"
        >
          {loading && <LoadingSpinner className="mr-1" />}
          <div>
            {data?.artworks.nbHits && !loading && (
              <strong>{numberFormat(data.artworks.nbHits)}</strong>
            )}{' '}
            artworks
            {!haveFilters ? (
              <>
                {' '}
                from <strong>{numberFormat(studioCount)}</strong> artists
              </>
            ) : null}
          </div>
        </Heading>
        <div className="flex justify-center space-x-4 lg:flex-none">
          <div className="relative">
            <Button
              type="button"
              variant={showFilters ? 'primaryYellow' : 'outline'}
              onClick={() => {
                dispatch({ type: 'TOGGLE_FILTERS' });
              }}
              className="whitespace-pre"
            >
              Filter{filtersCount > 0 && ` (${filtersCount})`}
            </Button>
          </div>
          <SearchArtworks
            initialSearchQuery={state.searchQuery}
            onQueryToTrackChanged={(search_term) => {
              trackSearch({ search_term });
            }}
            onSubmit={(query) => {
              dispatch({ type: 'SEARCH_QUERY', payload: query });
            }}
          />
        </div>
        <div className="hidden lg:block lg:flex-1"></div>
      </div>
      <AnimatePresence initial={false}>
        {showFilters && (
          <motion.div
            initial={{ height: 0, opacity: 0 }}
            exit={{ height: 0, opacity: 0 }}
            animate={{ height: 'auto', opacity: 1 }}
          >
            <Filters
              initialValues={initialState.filterValues}
              minPrice={MIN_PRICE}
              maxPrice={MAX_PRICE}
              onSubmit={(values) => {
                dispatch({ type: 'UPDATE_FILTER_VALUES', payload: values });
              }}
              onReset={onReset}
              className="mb-4"
            />
          </motion.div>
        )}
      </AnimatePresence>
      <InfiniteArtworkList
        haveFilters={haveFilters}
        data={data}
        error={error}
        loading={loading}
        onLoadMore={onLoadMore}
        haveMorePages={haveMorePages}
        itemListId="discover"
        itemListName="Discover"
        page={page}
      />
    </div>
  );
}
