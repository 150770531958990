import { LOCALE, CURRENCY } from './constants';

export function numberFormat(
  value: number,
  options?: Intl.NumberFormatOptions
) {
  try {
    const currency = options ? options.currency : CURRENCY.NOK;
    return Intl.NumberFormat(LOCALE, { currency, ...options }).format(value);
  } catch (err) {
    return value.toString();
  }
}

export function currencyFormat(
  value: number,
  options?: Omit<Intl.NumberFormatOptions, 'style'>
) {
  let maximumFractionDigits, minimumFractionDigits;
  if (value % 1 === 0) {
    minimumFractionDigits = 0;
  } else {
    maximumFractionDigits = 2;
    minimumFractionDigits = 2;
  }
  const currency = options ? options.currency : CURRENCY.NOK;
  return numberFormat(value, {
    ...options,
    style: 'currency',
    currencyDisplay: 'code',
    maximumFractionDigits,
    minimumFractionDigits,
    currency,
  });
}
