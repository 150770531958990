import React, { useRef, useEffect } from 'react';

export function useEffectSkipFirstRender(
  effect: React.EffectCallback,
  deps?: React.DependencyList | undefined
) {
  const isFirstRun = useRef(true);
  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
    effect();
  }, deps);
}
