import { Layout } from '@components/common/Layout';
import {
  ARTWORKS_QUERY,
  Discover,
  initialVariables,
} from '@components/discover';
import { CurationsSection } from '@components/curations/CurationsSection';
import { fetchStudioMetadata } from '@lib/data/metadata';
import {
  fetchStartPage,
  SanityStartPage,
  CuratedArtwork,
} from '@lib/sanity.server';
import { GetStaticProps } from 'next';
import React from 'react';
import { useRouter } from 'next/router';
import {
  EditionsQuery,
  EditionsQueryVariables,
  EditionsLocation,
  useEditionsQuery,
  ArtworksQuery,
  ArtworksQueryVariables,
  StartPageQuery,
  StartPageQueryVariables,
} from '@generated/codegen';
import { initializeApollo } from '@lib/graphql/client';
import { usePreviewSubscription } from '@lib/sanity';
import { startPageQuery } from '@lib/sanity.queries';
import { numberFormat } from '@lib/number-format';
import { CURATED_ARTWORK_FRAGMENT } from '@lib/graphql/fragments/artwork';
import { EDITIONS_QUERY } from '@components/editions/graphql';
import { gql } from '@apollo/client';
import { ArtworkModal } from '@components/artwork/ArtworkModal';
import classNames from 'classnames';
import { NextSeo } from 'next-seo';
import { useTailwindScreen } from '@lib/hooks/useTailwindScreen';
import { HomepageEditionsSection } from '../components/editions/editionsSections';
import { NewsletterSignUpModal } from '@components/newsletter/newsletterSignUpModal';

function getArtworkIds(curations: NonNullable<SanityStartPage['curations']>) {
  return curations
    .map((curation) => {
      const artworkItems = curation.items?.filter(
        (item) => item._type === 'artwork'
      ) as CuratedArtwork[];
      return artworkItems.map((item) => item.artworkId);
    })
    .flat();
}
const editionsQueryVariables = {
  locations: [EditionsLocation.Editions],
};
const START_PAGE_QUERY = gql`
  ${CURATED_ARTWORK_FRAGMENT}
  query startPage($artworkIds: [ID!]!) {
    artworksByIds(ids: $artworkIds) {
      ...CuratedArtwork
    }
    techniques {
      id
      nb
    }
  }
`;

interface Props {
  studioCount: number;
  initialSanityStartPage: SanityStartPage | null;
}
const description =
  'Atelie is an online platform offering visitors the opportunity to explore and acquire artworks from professional artists either online or directly from their studios. All artists featured on our platform undergo an evaluation by a group of selected artists and professionals.';

export default function DiscoverPage({
  studioCount,
  initialSanityStartPage,
}: Props) {
  const router = useRouter();
  const { data: sanityStartPage } =
    usePreviewSubscription<SanityStartPage | null>(startPageQuery, {
      initialData: initialSanityStartPage,
      enabled: Boolean(router.isPreview && initialSanityStartPage),
    });
  const artworkId = router.query.artworkId?.toString();
  const screen = useTailwindScreen();

  const editions = useEditionsQuery({ variables: editionsQueryVariables });
  // show artwork in fullscreen view on mobile
  const fullscreenDetailView = screen.currentScreen === null;
  return (
    <Layout hideChrome={Boolean(artworkId) && fullscreenDetailView}>
      <NextSeo description={description} openGraph={{ description }} />
      <div className="px-4 mx-auto max-w-screen-2xl">
        <ArtworkModal artworkId={artworkId} fullscreen={fullscreenDetailView} />
        <NewsletterSignUpModal />
        <div className={classNames({ 'hidden md:block': artworkId })}>
          <div
            className="px-4 mt-4 mb-6 font-serif text-2xl text-center md:text-4xl md:mb-8"
            data-testid="HeaderSection_Title"
          >
            Discover and buy art directly from {numberFormat(studioCount)}{' '}
            professional artists
          </div>
          {editions && editions.data && (
            <div className="mb-12">
              <HomepageEditionsSection data={editions.data} />{' '}
            </div>
          )}
          {sanityStartPage && sanityStartPage.curations && (
            <div className="mb-12">
              <CurationsSection curations={sanityStartPage.curations} />
            </div>
          )}
          <Discover studioCount={studioCount} />
        </div>
      </div>
    </Layout>
  );
}

export const getStaticProps: GetStaticProps<Props> = async ({ preview }) => {
  const apolloClient = initializeApollo();
  const [studioMetadata, sanityStartPage] = await Promise.all([
    fetchStudioMetadata(),
    fetchStartPage(preview),
  ]);

  const artworkIds: string[] = getArtworkIds(
    (sanityStartPage && sanityStartPage.curations) || []
  );
  await apolloClient.query<StartPageQuery, StartPageQueryVariables>({
    query: START_PAGE_QUERY,
    variables: {
      artworkIds,
    },
  });
  await apolloClient.query<ArtworksQuery, ArtworksQueryVariables>({
    query: ARTWORKS_QUERY,
    variables: initialVariables,
  });

  await apolloClient.query<EditionsQuery, EditionsQueryVariables>({
    query: EDITIONS_QUERY,
    variables: editionsQueryVariables,
  });

  const metadata = {
    studios: studioMetadata,
  };
  return {
    revalidate: 60,
    props: {
      metadata,
      studioCount: studioMetadata.count || 0,
      initialApolloState: apolloClient.cache.extract(),
      initialSanityStartPage: sanityStartPage || null,
    },
  };
};
