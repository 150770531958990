import { useState, useEffect } from 'react';
import { useAuthUser } from '@lib/authentication';
import { Modal } from '@components/ui/Modal';
import { NewsletterSignUpForm } from './newsletterSignUpForm';

const newsLetterModalKey = 'nlmk';
export function NewsletterSignUpModal() {
  const [showModal, setShowModal] = useState(false);
  const [customerSignedUp, setCustomerSignedUp] = useState(false);
  const { user, loading } = useAuthUser();

  const handleOnSignUp = () => {
    setTimeout(() => setCustomerSignedUp(true), 500);
    window && window.sessionStorage.setItem(newsLetterModalKey, 'true');
    window && window.localStorage.setItem(newsLetterModalKey, 'true');
  };

  const handlClose = () => {
    setShowModal(false);
    window && window.sessionStorage.setItem(newsLetterModalKey, 'true');
  };

  useEffect(() => {
    if (window) {
      const ssSignupHandled = Boolean(
        window.sessionStorage.getItem(newsLetterModalKey)
      );
      const lsSignupHandled = Boolean(
        window.localStorage.getItem(newsLetterModalKey)
      );

      if (!loading && !user && !ssSignupHandled && !lsSignupHandled) {
        setTimeout(() => setShowModal(true), 45000);
      }
    }
  }, [user, loading]);

  return (
    <Modal
      open={showModal && !customerSignedUp}
      onClose={() => handlClose()}
      className="bg-yellow"
    >
      <NewsletterSignUpForm onSignedUp={handleOnSignUp} />
    </Modal>
  );
}
