import React, { useEffect, useCallback, useRef } from 'react';
import { Checkbox } from '@components/ui/Checkbox';
import { Input } from '@components/ui/Input';
import { Button } from '@components/ui/Button';
import {
  Formik,
  Form,
  Field,
  FieldProps,
  useFormikContext,
  FormikErrors,
} from 'formik';
import debounce from 'lodash/debounce';
import { useRanger } from 'react-ranger';
import classNames from 'classnames';
import { X } from 'react-feather';
import { Radio } from '@components/ui/Radio';
import { AlgoliaArtworkIndex, useTechniquesQuery } from '@generated/codegen';

export interface FilterValues {
  index: AlgoliaArtworkIndex;
  techniques: string[];
  minPrice: string;
  maxPrice: string;
}

function AutoSubmit({
  debounceMs,
  onReset,
}: {
  debounceMs: number;
  onReset: () => void;
}) {
  const isFirstRun = useRef(true);
  const formik = useFormikContext<FilterValues>();
  const debouncedSubmit = useCallback(
    debounce(() => formik.submitForm(), debounceMs),
    [debounceMs, formik.submitForm]
  );
  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
    if (!formik.dirty) {
      onReset();
      return;
    }
    debouncedSubmit();
  }, [debouncedSubmit, onReset, formik.dirty, formik.values]);
  return null;
}

function Legend({ children }: { children: React.ReactNode }) {
  return <legend className="mb-2 md:mb-4">{children}</legend>;
}

function PriceRangeInput({
  minPrice,
  maxPrice,
}: {
  minPrice: number;
  maxPrice: number;
}) {
  const formik = useFormikContext<FilterValues>();
  const onChange = useCallback((values: number[]) => {
    const [min, max] = values;
    if (min >= max || max <= min) {
      return;
    }
    formik.setFieldValue('minPrice', min.toString());
    formik.setFieldValue('maxPrice', max.toString());
  }, []);
  const { getTrackProps, handles, segments } = useRanger({
    values: [
      formik.values.minPrice ? parseInt(formik.values.minPrice) : minPrice,
      formik.values.maxPrice ? parseInt(formik.values.maxPrice) : maxPrice,
    ],
    onChange: onChange,
    onDrag: onChange,
    min: minPrice,
    max: maxPrice,
    stepSize: 500,
  });
  return (
    <div className="h-4 px-2">
      <div className="h-1 bg-gray-300" {...getTrackProps()}>
        {segments.map(({ getSegmentProps }, i) => {
          const { key, ...segmentProps } = getSegmentProps();
          return (
            <div
              key={key}
              {...segmentProps}
              className={classNames('h-full', { 'bg-primary': i === 1 })}
            />
          );
        })}
        {handles.map(({ getHandleProps }) => {
          const { key, ...handleProps } = getHandleProps();
          return (
            <button
              key={key}
              className="w-5 h-5 bg-white border border-gray-900 rounded-full outline-none"
              {...handleProps}
            />
          );
        })}
      </div>
    </div>
  );
}

export function Filters({
  initialValues,
  onSubmit,
  onReset,
  maxPrice,
  minPrice,
  className,
}: {
  initialValues: FilterValues;
  onSubmit: (values: FilterValues) => void;
  onReset: () => void;
  maxPrice: number;
  minPrice: number;
  className?: string;
}) {
  const { data } = useTechniquesQuery();
  return (
    <Formik<FilterValues>
      initialValues={initialValues}
      onSubmit={onSubmit}
      validate={(values) => {
        const errors: FormikErrors<FilterValues> = {};
        if (values.minPrice && !/^[0-9]+$/.test(values.minPrice)) {
          errors.minPrice = 'Enter a number';
        }
        if (values.maxPrice && !/^[0-9]+$/.test(values.maxPrice)) {
          errors.maxPrice = 'Enter a number';
        }
        return errors;
      }}
    >
      {({ values, setFieldValue, dirty, resetForm }) => {
        const showAllTechniques = values.techniques.length === 0;
        return (
          <Form data-testid="FiltersForm" className={className}>
            <div className="space-y-4 md:divide-gray-200 md:flex md:justify-center md:space-x-4 lg:space-x-8 md:space-y-0 md:divide-x">
              <AutoSubmit debounceMs={100} onReset={onReset} />
              <fieldset>
                <Legend>Sort</Legend>
                <div className="grid gap-x-5 gap-y-3">
                  {[
                    {
                      index: AlgoliaArtworkIndex.Artworks,
                      children: 'Random',
                    },
                    {
                      index: AlgoliaArtworkIndex.ArtworksByFavoritesDesc,
                      children: 'Most liked',
                    },
                    {
                      index: AlgoliaArtworkIndex.ArtworksByCreatedDateDesc,
                      children: 'Newest',
                    },
                  ].map(({ index, children }) => {
                    return (
                      <div key={index}>
                        <Field
                          name="index"
                          type="radio"
                          value={index}
                          variant="tag"
                          as={Radio}
                        >
                          {children}
                        </Field>
                      </div>
                    );
                  })}
                </div>
              </fieldset>
              <fieldset className="md:pl-4 lg:pl-8">
                <Legend>Category</Legend>
                <div className="grid grid-cols-2 gap-x-5 gap-y-3">
                  <div>
                    <Checkbox
                      checked={showAllTechniques}
                      variant="tag"
                      onChange={() => {
                        setFieldValue('techniques', []);
                      }}
                      removable={false}
                    >
                      Show all
                    </Checkbox>
                  </div>
                  {data &&
                    data.techniques.map((technique) => {
                      const checked = values.techniques.includes(technique.id);
                      return (
                        <div key={technique.id}>
                          <Checkbox
                            value={technique.id}
                            checked={checked}
                            variant="tag"
                            onChange={(event) => {
                              const { value } = event.target;
                              if (checked) {
                                setFieldValue(
                                  'techniques',
                                  values.techniques.filter(
                                    (t) => t !== technique.id
                                  )
                                );
                              } else {
                                setFieldValue('techniques', [
                                  ...values.techniques,
                                  value,
                                ]);
                              }
                            }}
                          >
                            {technique.en}
                          </Checkbox>
                        </div>
                      );
                    })}
                </div>
              </fieldset>
              <fieldset className="mb-4 md:max-w-xs md:pl-4 lg:pl-8">
                <Legend>Price</Legend>
                <div className="flex mb-4 space-x-2">
                  <Field name="minPrice">
                    {({
                      field,
                      meta,
                    }: FieldProps<FilterValues['minPrice'], FilterValues>) => (
                      <Input
                        id={field.name}
                        wrapperClassName="flex-1"
                        label="From"
                        type="text"
                        inputMode="numeric"
                        pattern="[0-9]*"
                        min={minPrice}
                        error={meta.touched ? meta.error : undefined}
                        className="w-full"
                        {...field}
                      />
                    )}
                  </Field>
                  <Field name="maxPrice">
                    {({
                      field,
                      meta,
                    }: FieldProps<FilterValues['maxPrice'], FilterValues>) => (
                      <Input
                        id={field.name}
                        wrapperClassName="flex-1"
                        label="To"
                        type="text"
                        inputMode="numeric"
                        pattern="[0-9]*"
                        min={minPrice}
                        error={meta.touched ? meta.error : undefined}
                        className="w-full"
                        {...field}
                      />
                    )}
                  </Field>
                </div>
                <PriceRangeInput minPrice={minPrice} maxPrice={maxPrice} />
              </fieldset>
            </div>
            {dirty && (
              <div className="flex justify-center mt-4">
                <Button
                  type="button"
                  variant="outline"
                  size="sm"
                  iconLeft={<X strokeWidth={1} />}
                  onClick={() => {
                    resetForm();
                  }}
                >
                  Remove all filters
                </Button>
              </div>
            )}
          </Form>
        );
      }}
    </Formik>
  );
}
